import React from 'react';

export default (props) => {
  return (
    <section className="index-hero">
    <div className="inner">
      <div className="content">
        <h1>Welcome to the Uplift Series </h1>
        <div className="subcopy">
        Our Sitecore Practice Lead and Technical Director Christian Brenner has spent the better part of two decades building and running high-performing digital teams. 
        </div>
      </div>
    </div>
  </section>
  )
}
