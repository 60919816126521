import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import HomeHero from '../components/home_hero'
import Layout from '../components/layout'
import PageListing from '../components/page_listing'
import HomeIntro from '../components/home_intro'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [home] = get(this, 'props.data.allContentfulHome.edges')
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <HomeHero {...home.node} />
          <HomeIntro {...home.node} />
          <PageListing pages={posts} />
        {/* <Pagination {...this.props.pageContext} /> */}
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: ASC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            json
          }
          deck {
            file {
              url
            }
          }
          formCheckboxText
          formCopyHeading {
            formCopyHeading
          }
          formThankYou {
            formThankYou
          }

        }
      }
    }
    allContentfulHome(
      filter: { contentful_id: { eq: "5ZTdg5KCS1sQFAAWBmFT03" } }
    ) {
      edges {
        node {
          title
          content {
            json
          }
        }
      }
    }
  }
`
