import React from 'react';
import RichText from './richtext';

export default (props) => {
  let content;

  if (props.content) {
    if (typeof props.content === 'object' && 'json' in props.content) {
      content = <RichText {...props.content} />;
    } else {
      content = props.content;
    }
  }
  return (
      <section className="index-intro">
        <div className="inner inner--small">
          <p>
            He’s built an independent Melbourne digital agency’s technical team from scratch, juggling a hands-on development role with being the technical face of the business for nearly a decade. He’s worked client-side as developer, architect and consultant on multiple occasions, and has been responsible for some of the largest Sitecore solutions in the world, including leading a team of over 90 developers, architects and tech leads in a scaled agile program for Australia’s largest hardware retailer. Most recently he’s been responsible for spearheading Isobar’s national Sitecore practice, driving innovation, quality and efficiency. He’s worked with many of our Isobar clients, helping to provide technical leadership and guidance, and to build their internal capability.
          </p>

          <p>
            In this series of articles, Christian joins forces with other experts from Team Orange who share his passion for helping clients to do it themselves. Together, they’re going to share their stories and their knowledge. We genuinely hope that sharing this experience and knowledge with you and your team will help you build capability, give you the confidence to try it yourself, and most of all help you to sort facts from fiction.
          </p>

          <p>
            Future articles will dive deeper into many areas that digital team leaders need to understand, if not grapple with, including leadership, talent, technology, tools, strategy, support and more.
          </p>
        </div>
      </section>
    );
  }
