import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export default (props) => {
  let stringLength = 180;
  if (props.maxLength) {
    stringLength = props.maxLength;
  }
  const plainText = ReactDOMServer.renderToString(documentToPlainTextString(props.json));

  return plainText ? plainText.substr(0, stringLength) + '...' : '';
};
