import React from 'react';
import RichTextPreview from './richtext_preview';
import { ArrowRight } from './icons';

export default (props) => {
  const previewText = <RichTextPreview {...props.description} />;
  let hasMore = false;
  if (props.deck) {
    if (props.deck.file) {
      hasMore = true
    }
  }

  const content = () => {
    return (
      <div className="content-wrap">
        <p className="title">{props.title}</p>
        <div className="preview">{previewText}</div>
        {hasMore && (
          <p className="read-more">
          <ArrowRight /> Read more
        </p>
        )}        
      </div>
    )
  }

  if (hasMore) {
    return (
      <a href={`/${props.slug}/`} className="page-preview">
        {content()}
      </a>
    );
  }
  
  return (
    <div className="page-preview">
      {content()}
    </div>
  )
};
